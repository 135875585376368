import * as React from "react";
import { Frame } from "../components/Frame/Frame";
import { Button } from "../components/Button/Button";
import { Header } from "../components/Header";
import { VideoFrame } from "../VideoFrame";
import Layout from "../components/Layout";
// TODO: get videos working on safari
// TODO: sound compoment
const IndexPage = () => {
  return (
    <Layout>
      <Header
        frame={
          <Frame
            firstBackground="#FF8B72"
            secondBackground="#FF1F00"
            showSoundOnButton
          >
            <VideoFrame src="/videos/home (nyc).mp4" />
          </Frame>
        }
        title="create, together"
        showMarquee
        marqueeTitle={"from & for the heart"}
      >
        <div
          style={{
            marginTop: 60,
          }}
        >
          {/* <Button
            to="https://apps.apple.com/us/app/studio-the-group-camcorder/id1582843261"
            isPrimary
          >
            <div
              style={{
                flexDirection: "row",
                alignItems: "center",
                display: "flex",
              }}
            >
              <img
                src="/record-icon.svg"
                height={25}
                style={{ marginRight: 10, marginBottom: 0 }}
              />
              hit record
            </div>
          </Button>

          <Button to="/blog">
            <div
              style={{
                flexDirection: "row",
                alignItems: "center",
                display: "flex",
              }}
            >
              <img
                src="/play-icon.svg"
                height={21}
                style={{ marginRight: 10, marginTop: 5 }}
              />
              press play
            </div>
          </Button> */}
        </div>
      </Header>
    </Layout>
  );
};

export default IndexPage;
